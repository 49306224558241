<template>
  <div>
    <b-modal
      ref="modal-cancel"
      modal
      centered
      size="lg"
      header-class="p-0  border-bottom-0"
      no-close-on-backdrop
      header-bg-variant="transparent border-bottom border-bottom-2"
      modal-class="modal-primary"
      title="Process"
      title-class="h2 text-white"
      @hidden="hideModal"
    >
      <!-- HEADER START -->
      <template v-slot:modal-header>
        <ModalHeader
          :data-client="dataClient"
          :title="'DELETE CR'"
          @close="hideModal()"
        />
      </template>
      <validation-observer ref="deleteCommentVal">
        <b-container>
          <b-row>
            <b-col cols="2" class="ml-1 bg-primary rounded-top paddingTop">
              <h6 class="text-white text-center">OBSERVATION</h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                name="Observation"
                rules="required"
                v-slot="{ errors, valid }"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="obs"
                  :state="errors[0] ? false : valid ? true : null"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                />
                <div class="text-danger" v-if="errors[0]">
                 Observation {{ errors[0] }}
                </div>
              </validation-provider>
            </b-col>
          </b-row>
        </b-container>
      </validation-observer>
      <template #modal-footer>
        <b-button @click="hideModal"> Cancel </b-button>
        <b-button variant="primary" @click="cancel"> Save </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import { ValidationObserver } from "vee-validate";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ModalHeader from "./ModalHeader.vue";
import ProcesCRServices from "../../../services/process-cr.services";
export default {
  components: {
    ModalHeader,
    ButtonSave,
    ButtonCancel,
    ValidationObserver,
  },
  props: {
    dataClient: {
      type: Object,
    },
  },
  data() {
    return {
      obs: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modal-cancel");
  },
  methods: {
    async cancel() {
      try {
        let validated = await this.$refs.deleteCommentVal.validate();
        if (validated) {
          let confirm = await this.showConfirmSwal();
          if (confirm.isConfirmed) {
            const params = {
              id_ncr: this.dataClient.ncr_id,
              obs: this.obs,
              id_user: this.currentUser.user_id,
            };
            const { status } = await ProcesCRServices.setProcessDelete(params);
            if (status == 200) {
              this.addPreloader();
              this.hideModal();
              this.$emit("updateTable");
              this.removePreloader();
              this.showToast("success", "top-right", "Success!", "CheckIcon");
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    hideModal() {
      this.$refs['modal-cancel'].hide();
      this.$emit("hideModal");
    },
  },
};
</script>
<style scoped>
.paddingTop {
  padding-top: 12px;
}
</style>
