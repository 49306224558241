<template>
  <div>
    <div class="border-top-primary border-3 border-table-radius px-0">
      <filter-slot
        :filter-principal="filterPrincipal"
        :filter="$route.meta.tab == 1 ? [] : filter"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refCallroundsCE'].refresh()"
      >
        <template #table>
          <b-table
            slot="table"
            ref="refCallroundsCE"
            small
            :items="myProvider"
            :fields="fieldsData"
            primary-key="id"
            table-class="text-nowrap"
            responsive="sm"
            show-empty
            sticky-header="70vh"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(lead_name)="data">
              <div class="d-flex flex-row">
                <status-account
                  :account="data.item"
                  :text="false"
                ></status-account>
                <div>
                  <router-link
                    class="m-0 text-bold"
                    :class="isDarkSkin ? 'text-warning' : 'text-primary'"
                    :to="{
                      name: 'specialist-digital-dashboard',
                      params: { idClient: `${data.item.id_account}` },
                    }"
                    target="_blank"
                  >
                    {{ data.item.lead_name }}
                  </router-link>
                  <b-badge variant="warning" v-if="data.item.module_id == 20">
                    CONNECTION
                  </b-badge>
                  <p class="m-0">{{ data.item.mobile }}</p>
                  <p class="m-0">{{ data.item.account }}</p>
                  <badge-risky-clients
                    :id-account="data.item.id_account"
                    :risk-level="data.item.risk_level"
                    :risk-description="data.item.risk_description"
                    :risk-type="data.item.risk_type"
                  />
                </div>
              </div>
            </template>
            <template #cell(request_by)="data">
              <p class="m-0">
                {{ data.item.request_by }}
              </p>
              <p class="m-0">{{ data.item.date_request_by | myGlobal }}</p>
            </template>
            <template #cell(process_by)="data">
              <div v-if="$route.meta.tab != 3">
                <p class="m-0">
                  {{ data.item.process_by }}
                </p>
                <p class="m-0">{{ data.item.date_process_by | myGlobal }}</p>
              </div>
              <div v-else class="d-flex align-items-end">
                <span class="mr-1">
                  <p class="m-0">{{ data.item.deleted_by }}</p>
                  <p class="m-0">{{ data.item.date_deleted_by | myGlobal }}</p>
                </span>
                <span class="text-info">
                  <feather-icon
                    icon="InfoIcon"
                    size="20"
                    v-b-tooltip.hover="data.item.delete_comment"
                  />
                </span>
              </div>
            </template>
            <template #cell(obtained_by)="data">
              <p class="m-0">
                {{ data.item.obtained_by }}
              </p>
              <p class="m-0">{{ data.item.date_obtained_by | myGlobal }}</p>
            </template>

            <template #cell(route_file)="data">
              <template v-if="moduleId == 11">
                <a
                  :href="data.item.route_file"
                  target="_blank"
                  v-if="!data.item.ncr_realtor_id"
                >
                  <amg-icon
                    class="cursor-pointer mt-0 mr-1 bigger text-danger"
                    icon="FilePdfIcon"
                    style="height: 20px; width: 20px"
                  />
                </a>
              </template>
              <template v-else>
                <a
                  :href="data.item.route_file"
                  target="_blank"
                  v-if="!data.item.ncr_realtor_id"
                >
                  <amg-icon
                    class="cursor-pointer mt-0 mr-1 bigger text-danger"
                    icon="FilePdfIcon"
                    style="height: 20px; width: 20px"
                  />
                </a>
                <feather-icon
                  icon="EyeIcon"
                  class="text-success mr-1 cursor-pointer"
                  v-else
                  style="height: 20px; width: 20px"
                  @click="openModalUploadCr(data.item)"
                />
              </template>
            </template>
            <template #cell(notes)="data">
              <a @click="openNotesModal(data.item)">
                <feather-icon
                  size="20"
                  :class="isDarkSkin ? 'text-success' : 'text-dark'"
                  icon="FileIcon"
                />
              </a>
            </template>
            <template #cell(score_id)="data">
              <div class="text-center">
                <router-link
                  v-if="data.item.stateS == 1"
                  :to="{
                    name: 'report-lead-specialistdigital-cr',
                    params: {
                      idfile: data.item.score_id,
                      idlead: data.item.lead_id,
                      accountId: data.item.id_account,
                      modul: 25,
                    },
                  }"
                >
                  <feather-icon
                    v-if="data.item.process == null"
                    size="20"
                    :class="isDarkSkin ? 'text-primary' : 'text-dark'"
                    icon="FileTextIcon"
                  />
                  <feather-icon
                    v-if="data.item.process == 1"
                    size="20"
                    class="text-success"
                    icon="FileTextIcon"
                  />
                </router-link>

                <router-link
                  v-if="data.item.stateS == 0"
                  :to="{
                    name: 'report-lead-specialistdigital-cr',
                    params: {
                      idfile: data.item.score_id,
                      idlead: data.item.lead_id,
                      accountId: data.item.id_account,
                      modul: 25,
                    },
                  }"
                >
                  <feather-icon
                    v-if="data.item.process == 1"
                    size="20"
                    class="text-success"
                    icon="FileTextIcon"
                  />
                  <feather-icon
                    v-else
                    v-b-tooltip.hover.left="
                      'You have to generate the credit report'
                    "
                    size="20"
                    class="text-white"
                    icon="FileTextIcon"
                  />
                </router-link>
              </div>
            </template>
            <template v-slot:cell(rounds)="data">
              <div
                v-if="data.item.ncr_id"
                class="text-center cursor-pointer"
                @click="openModalRounds(data.item)"
              >
                <b-badge v-if="data.item.total_rounds" variant="primary">
                  {{ data.item.total_rounds }}
                </b-badge>
                <b-badge v-else variant="primary"> 0 </b-badge>
              </div>

              <div v-else class="text-center cursor-pointer">
                <b-badge
                  v-b-tooltip.hover.left="
                    'You have to generate the credit report'
                  "
                  variant="primary"
                >
                  0
                </b-badge>
              </div>
            </template>
            <template #cell(work_plan)="data">
              <div class="d-flex justify-content-center">
                <b-button
                  variant="outline"
                  class="d-flex justify-content-center cursor-pointer p-0"
                  @click="openWorkPlanModal(data.item)"
                >
                  <feather-icon
                    v-if="$route.meta.tab == 1"
                    :icon="
                      data.item.process == 1 &&
                      data.item.stateS == 1 &&
                      data.item.id_work_plan
                        ? 'EyeIcon'
                        : 'PlusCircleIcon'
                    "
                    :class="
                      data.item.process == 1 &&
                      data.item.stateS == 1 &&
                      data.item.id_work_plan
                        ? 'text-success'
                        : 'text-primary'
                    "
                    v-b-tooltip.hover.left="
                      data.item.process == 1 &&
                      data.item.stateS == 1 &&
                      data.item.id_work_plan
                        ? 'SHOW'
                        : 'ADD'
                    "
                    size="20"
                    style="cursor: pointer"
                  />
                  <feather-icon
                    v-else
                    icon="EyeIcon"
                    class="text-success"
                    v-b-tooltip.hover.left="'SHOW'"
                    size="20"
                    style="cursor: pointer"
                  />
                </b-button>
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="d-flex justify-content-center">
                <b-button
                  pill
                  variant="success"
                  class="text-center widthClients text-light"
                  @click="done(data.item)"
                  :disabled="!(data.item.process == 1 && data.item.stateS == 1)"
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="15"
                    :class="isDarkSkin ? 'text-white mr-1' : 'text-white mr-1'"
                  />DONE
                </b-button>
                <b-button
                  pill
                  variant="danger"
                  class="text-center widthClients text-light ml-1"
                  @click="openDeleteComment(data.item)"
                >
                  <feather-icon
                    icon="XIcon"
                    size="15"
                    :class="isDarkSkin ? 'text-white mr-1' : 'text-white mr-1'"
                  />DELETE
                </b-button>
              </div>
            </template>
          </b-table>
        </template>
      </filter-slot>
    </div>
    <modal-add-work-plan
      v-if="workPlanController"
      :id-work-plan="workPlanData.id_work_plan"
      :id-ncr="workPlanData.ncr_id"
      :client-name="workPlanData.lead_name"
      :client_id="workPlanData.id_account"
      :account="workPlanData.account"
      :type-tap="$route.meta.tab"
      @reload="$refs['refCallroundsCE'].refresh()"
      @close="workPlanController = false"
    />
    <modal-done
      v-if="modalDoneState"
      :dataClient="dataDone"
      @updateTable="$refs['refCallroundsCE'].refresh()"
      @hideModal="modalDoneState = false"
    />
    <list-all-notes-modal
      v-if="isAllNotesActive"
      :id-account="notesClient.idAccount"
      :name-client="notesClient.nameClient"
      @close="isAllNotesActive = false"
    />
    <add-round-modal
      v-if="addRoundModal"
      :data-round="itemClient"
      :validate-modal="0"
      @refresh="$refs['refCallroundsCE'].refresh()"
      @close="addRoundModal = false"
    />
    <modal-delete-comment
      v-if="modalDeleteCommentState"
      :dataClient="dataDeleteClient"
      @updateTable="$refs['refCallroundsCE'].refresh()"
      @hideModal="modalDeleteCommentState = false"
    />
    <upload-realtor-cr
      v-if="activeModalUploadCr"
      :client-data="client"
      @close="activeModalUploadCr = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import fields from "../../data/fields.preocess-cr.data";
import ProcesCRServices from "../../../services/process-cr.services";
import IconStatus from "./IconStatus.vue";
import ModalAddWorkPlan from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ModalAddWorkPlan.vue";
import ListAllNotesModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListAllNotesModal.vue";
import ModalDone from "../modals/ModalDone.vue";
import ModalDeleteComment from "../modals/ModalDeleteComment.vue";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients";
import UploadRealtorCr from "@/views/specialist-digital/views/cr-realtor/components/AddFileDragAndDrop.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  components: {
    IconStatus,
    ModalAddWorkPlan,
    ListAllNotesModal,
    ModalDone,
    AddRoundModal,
    ModalDeleteComment,
    BadgeRiskyClients,
    UploadRealtorCr,
    StatusAccount,
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filter: [
        {
          type: "select",
          margin: true,
          showLabel: true,
          label: "Specialist",
          model: null,
          options: [],
          reduce: "id",
          selectText: "label",
          cols: 12,
        },
      ],
      startPage: 0,
      toPage: 0,
      totalRows: 0,
      fields: fields,
      isBusy: false,
      workPlanData: {},
      workPlanController: false,
      modalDoneState: false,
      dataDone: {},
      isAllNotesActive: false,
      notesClient: {
        idAccount: null,
        nameClient: null,
      },
      addRoundModal: false,
      modalDeleteCommentState: false,
      dataDeleteClient: {},
      client: {},
      activeModalUploadCr: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    fieldsData() {
      if (this.$route.meta.tab == 1) {
        return this.fields.filter((item) => item.key != "process_by");
      } else {
        return this.fields.filter((item) => item.key != "actions");
      }
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    const { data } = await ProcesCRServices.getAdvisors();
    this.filter[0].options = data;
    if (this.$route.meta.tab == 3) {
      this.fields.find((item) => item.key == "process_by").label = "Deleted by";
    }
  },
  methods: {
    openWorkPlanModal(data) {
      this.workPlanData = data;
      this.workPlanController = true;
    },

    openModalRounds(data) {
      this.itemClient = {
        id: data.ncr_id,
        idAccount: data.id_account,
        account: data.account,
        clientName: data.lead_name,
        clientData: {
          dob: data.dob,
          address: data.address,
          ssn: data.ssn,
          origin_country: data.origin_country,
        },
      };
      this.addRoundModal = true;
    },
    openNotesModal(data) {
      this.isAllNotesActive = true;
      this.notesClient.idAccount = data.id_account;
      this.notesClient.nameClient = data.lead_name;
    },

    async myProvider() {
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          type_tab: this.$route.meta.tab,
          id_specialist: this.filter[0].model,
          orderby: this.$route.meta.tab == 1 ? 9 : 11,
          orden: this.$route.meta.tab == 1 ? "asc" : "desc",
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
        };

        const { status, data } = await ProcesCRServices.getProcessCR(params);
        if (status == 200) {
          this.startPage = data.from;
          this.toPage = data.to;
          this.totalRows = data.total;
          this.paginate.currentPage = data.current_page;
          this.paginate.perPage = data.per_page;

          return data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    done(data) {
      this.modalDoneState = true;
      this.dataDone = data;
    },
    openDeleteComment(data) {
      this.modalDeleteCommentState = true;
      this.dataDeleteClient = data;
    },
    openModalUploadCr(row) {
      this.addPreloader();
      this.client.name = row.lead_name;
      this.client.account = row.account;
      this.client.realtor_id = row.ncr_realtor_id;
      this.client.account_id = row.id_account;
      this.client.read_status = 1;
      this.activeModalUploadCr = true;
    },
    async cancel(data) {
      try {
        let confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          const params = {
            id_ncr: data.ncr_id,
            id_user: this.currentUser.role_id,
          };
          const { status } = await ProcesCRServices.setProcessDelete(params);
          if (status == 200) {
            this.addPreloader();
            this.$refs["refCallroundsCE"].refresh();
            this.removePreloader();
            this.showToast("success", "top-right", "Success!", "CheckIcon");
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.padding-pill {
  margin-top: 5px;
  padding: 9px 10px 7px 10px;
}
.cursor-pointer {
  cursor: pointer;
}
.text-bold {
  font-weight: bold;
}
</style>
