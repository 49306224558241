export default [
    {
        key: "lead_name",
        label: "Name",
        tdClass: "pt-1",
        visible: true
    },
    {
        key: "request_by",
        label: "requested by",
        tdClass: "pt-1",
        visible: true
    },
    {
        key: "obtained_by",
        label: "obtained by",
        tdClass: "pt-1",
        visible: true
    },
    {
        key: "process_by",
        label: "processed by",
        tdClass: "pt-1",
        visible: true
    },
    {
        key: "type_document",
        label: "document",
        thClass: "text-center ",
        tdClass: "text-center pt-1",
        visible: true

    },
    {
        key: "notes",
        thClass: "text-center",
        tdClass: "text-center pt-1",
        visible: true

    },
    {
        key: "route_file",
        label: "pdf",
        thClass: "text-center",
        tdClass: "text-center pt-1",
        visible: true

    },
    {
        key: "score_id",
        label: "cr",
        thClass: "text-center",
        tdClass: "text-center pt-1",
        visible: true

    },
    {
        key: "rounds",
        thClass: "text-center",
        tdClass: "pt-1",
        visible: true
    },
    {
        key: "work_plan",
        label: "work plan",
        thClass: "text-center",
        tdClass: "pt-1",
        visible: true
    },
    {
        key: "actions",
        thClass: "text-center pt-1",
        tdClass: "pt-1",
        visible: true
    }
]